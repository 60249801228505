import styled from "@emotion/styled"
import { FormControlLabel, FormLabel, Radio } from "@mui/material"
import { Field, useFormikContext } from "formik"
import { RadioGroup } from "formik-mui"

const Styled = {
  Selector: styled(Field)`
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 9px;
    margin: 15px 0;
    ${(p) => p.theme.breakpoints.up("md")} {
      gap: 11px;
    }

    .MuiFormControlLabel-label {
      text-align: center;
      border: solid 1.5px ${(p) => p.theme.colors.silver};
      width: 76px;
      line-height: 37px;
      color: ${(p) => p.theme.colors.marine};
      display: block;
      ${(p) => p.theme.breakpoints.up("md")} {
        width: 110px;
        font-weight: bold;
        line-height: 49px;
      }

      &:hover {
        border-color: ${(p) => p.theme.colors.bluishGrey};
      }
    }

    .Mui-checked + .MuiFormControlLabel-label {
      border-color: ${(p) => p.theme.colors.duskBlue};
      color: ${(p) => p.theme.colors.paleGrey};
      background-color: ${(p) => p.theme.colors.duskBlue};
    }
  `,
  FormLabel: styled(FormLabel)`
    text-align: left; /* flipped by mui */
    transform: none !important;
  `,
}

type CommonToggleButtonsProps = {
  name: string
  label: string
  required?: boolean
  buttons: { value: string; label: string }[]
}

const CommonToggleButtons = ({
  name,
  required = false,
  label,
  buttons,
}: CommonToggleButtonsProps) => {
  const { isSubmitting, values } = useFormikContext()
  const initialValue = values[name]

  return (
    <Styled.Selector component={RadioGroup} name={name}>
      <Styled.FormLabel required={required}>{label}</Styled.FormLabel>
      {buttons.map((button, i) => (
        <FormControlLabel
          key={i}
          value={button.value}
          control={<Radio disabled={isSubmitting} name={name} />}
          label={button.label}
          disabled={isSubmitting}
          checked={initialValue === button.value}
        />
      ))}
    </Styled.Selector>
  )
}

export default CommonToggleButtons
