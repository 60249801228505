import { NavigationLink } from "common/NavigationLinks"
import NextLink from "common/NextLink"
import { memo } from "react"
import Shared from "theme/shared"
import Styled from "./FooterStyled"

type FooterNavLinkProps = { section: NavigationLink }

const FooterNavLink = ({ section }: FooterNavLinkProps) => {
  if (!Array.isArray(section.link)) return null
  return (
    <>
      <Shared.Desktop>
        <Styled.Title>{section.title}</Styled.Title>
      </Shared.Desktop>
      {section.link.map((item, index) => (
        <NextLink key={index} href={item.link} passHref>
          <Styled.Link href={item.link}>{item.title}</Styled.Link>
        </NextLink>
      ))}
    </>
  )
}

export default memo(FooterNavLink)
