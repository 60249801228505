import styled from "@emotion/styled"
import { MDXProvider } from "@mdx-js/react"
import { Container } from "@mui/material"
import NextLink from "common/NextLink"
import { FC } from "react"

const StyledWrapper = styled.div`
  font-size: 18px;
  text-align: left; /* flipped by mui */
  margin: 15px auto 50px;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin: 30px auto 50px;
  }
`

const StyledAnchor = styled.a`
  font-weight: bold;
  color: ${(p) => p.theme.colors.duskBlue};
`

const StyledTableWrapper = styled.div`
  overflow-y: auto;
  transform: rotateX(180deg);
`

const StyledTable = styled.table`
  min-width: 650px;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  transform: rotateX(180deg);
`

export const Anchor = ({ href, target, children }: any) => (
  <NextLink href={href} passHref>
    <StyledAnchor href={href} target={target}>
      {children}
    </StyledAnchor>
  </NextLink>
)

const Table: FC<any> = ({ props, children }) => (
  <StyledTableWrapper>
    <StyledTable {...props}>{children}</StyledTable>
  </StyledTableWrapper>
)

const Wrapper: FC<any> = ({ children }) => (
  <Container>
    <StyledWrapper>{children}</StyledWrapper>
  </Container>
)

const components = {
  wrapper: Wrapper,
  h1: styled.h1`
    margin: 10px 0;
  `,
  h2: styled.h2`
    font-size: 22px;
    margin: 20px 0;
    color: ${(p) => p.theme.colors.duskBlue};
    font-weight: bold;

    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 28px;
    }
  `,
  h3: styled.h3`
    font-size: 18px;
    margin: 10px 0;
    color: ${(p) => p.theme.colors.duskBlue};
    font-weight: bold;

    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  `,
  h4: styled.h4`
    font-size: 22px;
    margin: 15px 0;
    color: ${(p) => p.theme.colors.oceanBlue};
    font-weight: bold;
  `,
  a: Anchor,
  p: styled.p`
    margin-top: 20px;
    line-height: 28px;
  `,
  table: Table,
  th: styled.th`
    color: ${(p) => p.theme.colors.marine};
    border: 1px solid ${(p) => p.theme.colors.marine};
    padding: 7px 5px;
    font-size: 16px;
    line-height: 1;
  `,
  td: styled.td`
    padding: 7px 5px;
    border: 1px solid ${(p) => p.theme.colors.marine};
  `,
  tbody: styled.tbody`
    tr:nth-of-type(even) {
      background-color: ${(p) => p.theme.colors.paleGreyTwo};
    }
  `,
  ul: styled.ul`
    margin-inline-start: -1.14em;
    /* padding: 0; */
    /* list-style-position: inside; */
  `,
}

const MarkdownProvider: FC<any> = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
)

export default MarkdownProvider
