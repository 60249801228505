import styled from '@emotion/styled'
import { Box, Button, Collapse, Container } from '@mui/material'
import { NavigationLink, NavigationSubLink } from "common/NavigationLinks"
import NextLink from "common/NextLink"
import { useEffect, useState } from "react"
import { DESKTOP_EXPANDED_HEIGHT, DESKTOP_HEIGHT } from "./HeaderStyled"

const Styled = {
  Button: styled(Button)<{ selected: boolean }>`
    font-weight: ${(p) => (p.selected ? "bold" : "normal")};
    ${(p) => p.theme.breakpoints.up("md")} {
      padding-left: 15px;
      padding-right: 15px;
    }
    font-size: 16px;
    color: ${(p) =>
      p.selected ? p.theme.colors.duskBlue : p.theme.colors.bluishGrey};
  `,
  NavBar: styled.div`
    background-color: ${(p) => p.theme.colors.whiteTwo};
    box-shadow: 0 10px 20px 0 ${(p) => p.theme.colors.darkIndigo};
  `,
  Box: styled(Box)`
    height: ${DESKTOP_EXPANDED_HEIGHT - DESKTOP_HEIGHT}px;
    display: flex;
    align-items: center;
  `,
  Container: styled(Container)`
    justify-content: flex-start;
    padding: inherit;
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding: 5px;
    }
  `,
}

type BottomNavMenuProps = {
  subLinks: NavigationSubLink[] | null
  active: NavigationLink
}

const HeaderNavBar = ({ subLinks, active }: BottomNavMenuProps) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => setLoading(false), [])
  return (
    <Styled.NavBar>
      <Container>
        <Collapse
          in={subLinks?.length > 0}
          timeout={loading ? 0 : "auto"}
          unmountOnExit
        >
          <Styled.Box>
            <Styled.Container>
              {subLinks?.map((item, i) => (
                <NextLink key={i} href={item.link} passHref>
                  <Styled.Button selected={item === active}>
                    {item.title}
                  </Styled.Button>
                </NextLink>
              ))}
            </Styled.Container>
          </Styled.Box>
        </Collapse>
      </Container>
    </Styled.NavBar>
  )
}

export default HeaderNavBar
