import styled from "@emotion/styled"
import { AppBar, Button, Container, Toolbar } from "@mui/material"

export const DESKTOP_HEIGHT = 90
export const DESKTOP_EXPANDED_HEIGHT = 160
export const MOBILE_HEIGHT = 56

const Styled = {
  AppBar: styled(AppBar)`
    justify-content: center;
  `,
  Button: styled(Button)<{ selected: boolean }>`
    font-weight: ${(p) => (p.selected ? "bold" : "normal")};
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color: ${(p) =>
      p.selected ? p.theme.colors.duskBlue : p.theme.colors.marine};
  `,
  Container: styled(Container)`
    display: flex;
    align-items: center;

    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 0 28px;
    }

    ${(p) => p.theme.breakpoints.down("sm")} {
      padding: 5px;
    }
  `,
  Toolbar: styled(Toolbar)`
    background-color: ${(p) => p.theme.colors.white};
    justify-content: center;
    border-bottom: 2px solid ${(p) => p.theme.colors.bluishGrey}1A;

    ${(p) => p.theme.breakpoints.down("sm")} {
      height: ${MOBILE_HEIGHT}px;
      z-index: ${(p) => p.theme.zIndex.modal};
    }

    ${(p) => p.theme.breakpoints.up("md")} {
      height: ${DESKTOP_HEIGHT}px;
    }
  `,
  Logo: styled.img`
    display: block;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-inline-end: 20px;
    }

    @media (min-width: 1320px) {
      margin-inline-end: -21px;
      position: relative;
      inset-inline-start: -41px;
    }
  `,
  HeaderSide: styled.div<{ justify: "start" | "end"; flex: 1 | 4 }>`
    display: flex;
    align-items: center;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: ${(p) => `flex-${p.justify}`};
    ${(p) => p.theme.breakpoints.up("md")} {
      flex-grow: ${(p) => `${p.flex}`};
    }
  `,
  MeetingButton: styled(Button)`
    padding: 6px 16px;
  `,
}

export default Styled
