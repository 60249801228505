import { Box, Container, styled } from "@mui/material"
import Config, { whatsappLink } from "common/Config"
import { GlobalContext } from "common/GlobalContext"
import { Desktop, useIsDesktop } from "common/MediaQueries"
import { formatPhone } from "common/utils/formatters"
import { useContext } from "react"
import FooterContacts from "./FooterContacts"
import FooterNavLink from "./FooterNavLink"
import Styled from "./FooterStyled"

const StyledEnvAndBuildTime = styled(Box)`
  margin-top: 15px;
`

const Footer = () => {
  const { links } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()
  return (
    <Styled.Footer>
      <Container>
        <Styled.OuterGrid container>
          {!isDesktop && (
            <>
              <Styled.Grid item xs={12}>
                <FooterNavLink section={links.FooterMobile} />
              </Styled.Grid>
              <Styled.Divider />
            </>
          )}
          <Styled.Grid item xs={12} md>
            <FooterNavLink section={links.ParentModels} />
          </Styled.Grid>
          {isDesktop && (
            <>
              <Styled.Grid item md>
                <FooterNavLink section={links.CustomerService} />
              </Styled.Grid>
              <Styled.Grid item md>
                <FooterNavLink section={links.About} />
              </Styled.Grid>
            </>
          )}
          {!isDesktop && <Styled.Divider />}
          <Styled.Grid item xs={12} md>
            <FooterContacts />
          </Styled.Grid>
          <Styled.Grid item xs={12} md>
            <Desktop>
              <Styled.Title>יצירת קשר</Styled.Title>
            </Desktop>
            <Styled.FooterText>
              מוקד שירות לקוחות -{" "}
              <Styled.FooterLinks href={`tel:${Config.ServiceNumber}`}>
                {formatPhone(Config.ServiceNumber)}
              </Styled.FooterLinks>
            </Styled.FooterText>
            <Styled.FooterText>
              וואטסאפ -
              <Styled.FooterLinks
                href={whatsappLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {formatPhone(Config.WhatsappNumber)}
              </Styled.FooterLinks>
            </Styled.FooterText>
            <Styled.FooterText>
              <Styled.FooterLinks href={`mailto:${Config.ServiceEmail}`}>
                {Config.ServiceEmail}
              </Styled.FooterLinks>
            </Styled.FooterText>
          </Styled.Grid>
          <Styled.Grid2 container>
            {isDesktop && (
              <Styled.Grid2 item lg={12} container>
                <Styled.Divider />
              </Styled.Grid2>
            )}

            <Styled.Grid item xs={12} md={9} /*margin={5} margindesktop={40}*/>
              <Styled.Note>
                תשומת ליבך, דוגמאות הצבעים והריפודים מוצגות לשם המחשה בלבד.
                תתכנה סטיות בפועל. מגוון הצבעים והחומרים כפוף לשינויים על פי
                מדיניות היצרן ולמגבלות מלאי. מפרט הרכבים המשווקים בישראל זהה
                למפרט המכוניות המוצגות באולמות התצוגה בלבד. לנתוני המעבדה.
              </Styled.Note>

              <Styled.Note>
                ** נתוני צריכת הדלק הם לפי בדיקות המעבדה. צריכת הדלק בפועל
                מושפעת גם מתנאי הדרך, מתחזוקת הרכב וממאפייני הנהיגה, ויכולה אף
                להגיע לפער משמעותי ביחס
              </Styled.Note>
            </Styled.Grid>

            <Styled.GridLogo container item xs={12} md={3}>
              <Styled.Note>Ⓒ ford israel</Styled.Note>
              <Styled.FooterLinks
                href={Config.MintSite}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Styled.Logo
                  src="/mint_logo.png"
                  sizes="100px"
                  srcSet="/mint_logo.png 1x, /mint_logo@2x.png 2x, /mint_logo@3x.png 3x"
                  alt="לוגו מינט"
                  width="100"
                  height="27"
                />
              </Styled.FooterLinks>
            </Styled.GridLogo>
            {process.env.ENV !== "production" && (
              <Styled.Grid
                item
                xs={12}
                md={9} /*margin={5} margindesktop={40}*/
              >
                <StyledEnvAndBuildTime>
                  <Styled.Note>
                    {process.env.ENV}{" "}
                    {process.env.BUILD_TIMESTAMP &&
                      `- ${new Date(
                        Number(process.env.BUILD_TIMESTAMP) * 1000 || "",
                      ).toLocaleString()}`}
                  </Styled.Note>
                  <Styled.Note>Origin: {process.env.ORIGIN}</Styled.Note>
                </StyledEnvAndBuildTime>
              </Styled.Grid>
            )}
          </Styled.Grid2>
        </Styled.OuterGrid>
      </Container>
    </Styled.Footer>
  )
}

export default Footer
