import styled from '@emotion/styled'
import { Field } from "formik"
import { TextField } from "formik-mui"

const Styled = {
  Field: styled(Field)`
    width: 100%;
  `,
}

type CommonTextAreaProps = {
  name: string
  label: string
  required?: boolean
  rows: number
}

const CommonTextArea = ({
  name,
  label,
  required = false,
  rows,
}: CommonTextAreaProps) => (
  <Styled.Field
    component={TextField}
    name={name}
    label={label}
    required={required}
    type="text"
    multiline
    rows={rows}
    helperText=" "
  />
)

export default CommonTextArea
