import { IconButton } from '@mui/material'
import Config, { whatsappLink } from "common/Config"
import { NavigationLink } from "common/NavigationLinks"
import NextLink from "common/NextLink"
import { memo, useState } from "react"
import SideDrawer from "./HeaderSideDrawer"
import Styled from "./HeaderStyled"

type HeaderMobileProps = {
  sections: NavigationLink[]
}

const HeaderMobile = ({ sections }: HeaderMobileProps) => {
  const [sideDrawerOpened, setSideDrawerOpened] = useState(false)

  return (
    <Styled.AppBar position="sticky" elevation={0}>
      <Styled.Toolbar>
        <Styled.Container>
          {/* right */}
          <Styled.HeaderSide justify="start" flex={4}>
            <IconButton
              aria-label="תפריט"
              color="secondary"
              href="#"
              onClick={(e) => {
                e.preventDefault()
                setSideDrawerOpened(true)
              }}
            >
              <img src="/Menu.svg" alt="Menu" width="16" height="16" />
            </IconButton>
          </Styled.HeaderSide>

          <SideDrawer
            sections={sections}
            onClose={() => setSideDrawerOpened(false)}
            open={sideDrawerOpened}
          />
          {/* Center */}
          <NextLink href="/">
            <Styled.Logo
              src="/LOGO.svg"
              alt="פורד מסחריות"
              width="83"
              height="31"
            />
          </NextLink>
          {/* left */}
          <Styled.HeaderSide justify="end" flex={1}>
            <IconButton
              aria-label="וואטסאפ"
              color="secondary"
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/Whatsapp.svg" alt="Whatsapp"  width="20" height="19" />
            </IconButton>

            <IconButton
              aria-label="מוקד שירות לקוחות"
              color="secondary"
              href={`tel:${Config.ServiceNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/Phone.svg" alt="Phone"  width="16" height="16" />
            </IconButton>
          </Styled.HeaderSide>
        </Styled.Container>
      </Styled.Toolbar>
    </Styled.AppBar>
  )
}

export default memo(HeaderMobile)
