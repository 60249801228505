const colors = {
  black: "#161616",
  white: "#fff",
  marine: "#092a46",
  oceanBlueTwo: "#0076ab",
  oceanBlueTransperent: "rgba(2, 118, 179, 0.65)",
  paleGrey: "#f4f7f9",
  silver: "#dee2e6",
  whiteTwo: "#f8f8f8",
  shadowColor: "#0E2B4E",
  duskBlue: "#21508a",
  oceanBlue: "#0276b3",
  bluishGrey: "#8794a1",
  paleGreyTwo: "#f2f5f8",
  brownishPurple: "#803846",
  cloudyBlue: "#a8bcd5",
  darkGreyBlue: "#284762",
  darkIndigo: "rgba(14, 43, 78, 0.12)",
  error: "#c44028",
  divider: "rgba(135, 148, 161, 0.1)",
}

export const pollutionColors = [
  "#29503e",
  "#006543",
  "#007a49",
  "#00904b",
  "#00a652",
  "#33992b",
  "#50b849",
  "#fdf100",
  "#ffcb05",
  "#f9a61a",
  "#f48221",
  "#f55820",
  "#d93b20",
  "#ca252c",
  "#ca252c",
]

export const safetyColors = [
  "#a72c31",
  "#f15a23",
  "#ffcb05",
  "#26b78c",
  "#31899f",
  "#238bca",
  "#1b6fb7",
  "#4170b6",
  "#435daa",
]

export default colors
