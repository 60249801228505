const ModelName = ({ name }: { name: string }) => {
  if (!name) return null

  const words = name.split(" ")
  if (words.length === 1) {
    return <>{name}</>
  }

  return (
    <>
      <span>{words[0]} </span>&nbsp;{words.slice(1).join(" ")}
    </>
  )
}

export default ModelName
