import createCache from "@emotion/cache"
import { Global } from "@emotion/react"
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { AppProps } from "next/app"
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl"
import GlobalStyle from "./GlobalStyle"
import theme from "./theme"

export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
})

const ThemeProviders = ({
  appProps,
  children,
}: {
  appProps: AppProps
  children: React.ReactNode
}) => (
  <AppCacheProvider {...appProps} emotionCache={cacheRtl}>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Global styles={GlobalStyle} />
      {children}
    </ThemeProvider>
  </AppCacheProvider>
)

export default ThemeProviders
