import styled from '@emotion/styled'
import { SvgIcon, SvgIconProps } from '@mui/material'

const StyledPath = styled.path`
  fill: ${(p) => p.theme.colors.duskBlue};
`
const SuccessIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 70.301 70.301">
    <g transform="translate(-4.5 -4.5)">
      <StyledPath d="M39.65 5A34.65 34.65 0 1 0 74.3 39.65 34.7 34.7 0 0 0 39.65 5zm0 66.067a31.378 31.378 0 1 1 31.417-31.34 31.449 31.449 0 0 1-31.417 31.34z" />
      <StyledPath
        d="M57.349 37.248c-5.313 5.467-10.626 10.857-15.939 16.324-2.772-2.849-5.467-5.7-8.239-8.547-1.463-1.54-3.773.77-2.31 2.31 3.157 3.234 6.237 6.468 9.394 9.7a1.675 1.675 0 0 0 2.31 0c5.7-5.852 11.4-11.7 17.094-17.479 1.386-1.538-.847-3.848-2.31-2.308z"
        transform="translate(-5.84 -7.3)"
      />
    </g>
  </SvgIcon>
)

export default SuccessIcon
