import styled from "@emotion/styled"
import { Divider, Grid, Link, Typography } from "@mui/material"

const Styled = {
  Footer: styled.footer`
    width: 100%;
    background-color: ${(p) => p.theme.colors.marine};
    display: flex;
    color: ${(p) => p.theme.colors.white};
    padding: 40px 70px 40px;
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding: 20px 10px 30px;
    }

    @media print {
      display: none;
    }
  `,
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${(p) => p.theme.colors.cloudyBlue};
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 25px;
      margin-bottom: 25px;
      background-color: ${(p) => p.theme.colors.darkGreyBlue};
    }
  `,
  Grid: styled(Grid)`
    flex-direction: column;
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      /* @noflip */
      /* text-align: right; */
    }
  `,
  OuterGrid: styled(Grid)`
    ${(p) => p.theme.breakpoints.up("md")} {
      gap: 20px;
    }
  `,
  Note: styled(Typography)`
    font-size: 14px;
    color: ${(p) => p.theme.colors.cloudyBlue};
    line-height: 1.71;
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 11px;
      line-height: 1.45;
      text-align: center;
    }
  `,
  Logo: styled.img`
    display: block;
    width: 100px;

    ${(p) => p.theme.breakpoints.up("md")} {
      padding-right: 10px;
    }
  `,
  FooterLinks: styled.a`
    color: ${(p) => p.theme.colors.white};
    line-height: 0.86;
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
    }
  `,
  Grid2: styled(Grid)``,
  GridLogo: styled(Grid)`
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding-top: 22px;
      justify-content: center;
      gap: 7px;
      flex-direction: column;
      align-items: center;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      justify-content: flex-end;
    }
  `,
  FooterText: styled(Typography)`
    line-height: 0.86;
    font-size: 14px;
    padding: 10px 0px 10px;
    display: block;
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      padding: 2px 0px 10px;
    }
  `,
  FooterContacts: styled.div`
    ${(p) => p.theme.breakpoints.down("sm")} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  `,
  FooterContact: styled.div`
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding: 5px;
    }
  `,
  Title: styled(Typography)`
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 12px;
    ${(p) => p.theme.breakpoints.down("sm")} {
      display: none;
    }
  `,
  Link: styled(Link)`
    line-height: 0.86;
    font-size: 14px;
    padding: 10px 0px 10px;
    display: block;
    cursor: pointer;
    white-space: nowrap;
    color: ${(p) => p.theme.colors.white};
    text-decoration-color: rgba(0, 0, 0, 0);
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding: 10px;
      display: inline-block;
    }
  `,
}

export default Styled
