import { UtmQuery } from "api"

const getUtmParams = (): UtmQuery => {
  const params = new URLSearchParams(window.location.search)
  const newParams = new URLSearchParams()
  for (const [name, value] of params) {
    newParams.append(name.toLowerCase(), value)
  }

  return {
    utmValues: [
      {
        key: newParams?.get("utm_medium") ? "utm_medium" : null,
        value: newParams?.get("utm_medium") ?? null,
      },
      {
        key: newParams?.get("utm_source") ? "utm_source" : null,
        value: newParams?.get("utm_source") ?? null,
      },
      {
        key: newParams?.get("utm_campaign") ? "utm_campaign" : null,
        value: newParams?.get("utm_campaign") ?? null,
      },
      {
        key: newParams?.get("utm_term") ? "utm_term" : null,
        value: newParams?.get("utm_term") ?? null,
      },
      {
        key: newParams?.get("utm_content") ? "utm_content" : null,
        value: newParams?.get("utm_content") ?? null,
      },
      {
        key: newParams?.get("fbclid") ? "fbclid" : null,
        value: newParams?.get("fbclid") ?? null,
      },
      {
        key: newParams?.get("gclid") ? "gclid" : null,
        value: newParams?.get("gclid") ?? null,
      },
    ],
    sid: newParams?.get("sid") ?? null,
    formName: "",
  }
}

export default getUtmParams
