// function encodeURISlug(name: string) {
//   return process.env.NODE_ENV !== "development"
//     ? name
//     : encodeURIComponent(name)
// }

export default function slugify(name: string) {
  return name.toLowerCase().trim().replace(/\s+/g, "-")
  // return forceEncode ? encodeURISlug(slug) : slug
}
