import { GlobalContext } from "common/GlobalContext"
import { formatPhone } from "common/utils/formatters"
import { memo, useContext } from "react"
import Styled from "./FooterStyled"

const FooterContacts = () => {
  const { contacts } = useContext(GlobalContext)
  return (
    <Styled.FooterContacts>
      <Styled.Title>נציגי שירות</Styled.Title>
      {contacts.map((item, index) => (
        <Styled.FooterContact key={index}>
          <Styled.FooterText>{item.area}</Styled.FooterText>
          <Styled.FooterText>
            {item.name} -{" "}
            <Styled.FooterLinks href={`tel:${formatPhone(item.phoneNumber)}`}>
              {formatPhone(item.phoneNumber)}
            </Styled.FooterLinks>
          </Styled.FooterText>
        </Styled.FooterContact>
      ))}
    </Styled.FooterContacts>
  )
}

export default memo(FooterContacts)
