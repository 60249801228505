import type * as api from "api"
import { createContext } from "react"
import type { Links } from "./NavigationLinks"

export type GlobalContextType = {
  models: api.ModelsVehicle[]
  contacts: api.SalesContact[]
  settings: { [key: string]: string }
  links: Links
}

export const GlobalContext = createContext<GlobalContextType>(null)
