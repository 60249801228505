export const max = {
  fullname: [50, "ניתן להזין עד 50 תווים"] as [number, string],
}

export const required = {
  default: "שדה חובה",
  firstName: "יש להזין שם פרטי",
  lastName: "יש להזין שם משפחה",
  phoneNumber: "יש להזין מספר טלפון",
  email: "יש להזין כתובת מייל",
  licenseNumber: "יש להזין לוחית רישוי",
  licenceOrChassis: "נא הכנס מספר רישוי או שילדה",
  modelId: "בחר דגם",
  area: "בחר איזור",
  skuOrName: "נא הכנס טקסט חופשי או מספר פריט",
}

export const regex = {
  fullname: /([a-zA-Zא-ת'"\\.-/,].+\s).+/,
  name: /^[A-Za-z\u00C0-\u017F\u0590-\u05fe ,.'-]+$/,
  phoneNumber: /^\+?(972|0)(-)?0?(([23489]{1}(-?\d){7})|[57]{1}(\d-?){7}\d)$/,
  licenseNumber: /^([0-9]{2}-[0-9]{3}-[0-9]{2}|[0-9]{3}-[0-9]{2}-[0-9]{3}|[0-9]{7,8})$/,
  licenceOrChassis: /^(([0-9]{2}-[0-9]{3}-[0-9]{2}|[0-9]{3}-[0-9]{2}-[0-9]{3}|[0-9]{7,8})|([A-Z]{2}[A-Z0-9]{12}))$/,
}

export const invalid = {
  fullname: "שם מלא לא תקין",
  name: "יש להזין אותיות בעברית / אנגלית בלבד",
  email: "כתובת המייל אינה תקינה",
  phoneNumber: "מספר הטלפון אינו תקין",
  licenseNumber: "נא להזין מספר בעל 7-8 תווים המורכב מספרות בלבד",
  licenceOrChassis: "מספר רישוי או שילדה לא תקין",
}
