import * as api from "api"
import { invalid, regex, required } from "common/forms"
import * as Yup from "yup"

type Override<T1, T2> = Omit<T1, keyof T2> & T2
type Contact = Override<api.Contact, { modelId: string }>

const schema = Yup.object({
  subjectType: Yup.mixed().required(required.default),
  firstName: Yup.string()
    .required(required.firstName)
    .matches(regex.name, invalid.name),
  lastName: Yup.string()
    .required(required.lastName)
    .matches(regex.name, invalid.name),
  email: Yup.string().required(required.email).email(invalid.email),
  phoneNumber: Yup.string()
    .required(required.phoneNumber)
    .matches(regex.phoneNumber, invalid.phoneNumber),
  comment: Yup.string(),
  isMailing: Yup.boolean(),
  licenseNumber: Yup.string()
    .nullable()
    .when("subjectType", {
      is: (type: api.ContactSubjectType) => type === "Service",
      then: (schema) =>
        schema
          .required(required.licenseNumber)
          .matches(regex.licenseNumber, invalid.licenseNumber),
      otherwise: (schema) => schema.nullable(),
    }),
  modelId: Yup.string()
    .nullable()
    .when("subjectType", {
      is: (type: api.ContactSubjectType) =>
        type === "Sale" || type === "Appointment",
      then: (schema) => schema.required(required.modelId),
      otherwise: (schema) => schema.nullable(),
    }),
  area: Yup.string()
    .nullable()
    .when("subjectType", {
      is: (type: api.ContactSubjectType) =>
        type === "Sale" || type === "Appointment",
      then: (schema) => schema.required(required.area),
      otherwise: (schema) => schema.nullable(),
    }),
  platform: Yup.mixed().required(required.default),
  mediaChannelId: Yup.string().nullable(),
}).defined()

export const initialFormState: Contact & api.UtmQuery = {
  subjectType: "Sale",
  modelId: "",
  platform: null,
  mediaChannelId: "",
  area: "",
  licenseNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  comment: "",
  isMailing: false,
  utmValues: [],
  sid: "",
  formName: "",
}

export const initialAppointmentFormState: Contact = {
  ...initialFormState,
  subjectType: "Appointment",
}

export default schema
