import styled from "@emotion/styled"
import { Typography, css } from "@mui/material"
import * as api from "api"
import { GlobalContext } from "common/GlobalContext"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import { trackLead } from "common/analytics"
import {
  CommonCheckbox,
  CommonForm,
  CommonInput,
  CommonResponseDialog,
  CommonSelect,
  CommonSubmit,
  CommonTextArea,
  CommonToggleButtons,
} from "common/forms"
import getUtmParams from "common/getUtmParams"
import { FormikProps } from "formik"
import { useRouter } from "next/router"
import { memo, useContext, useEffect, useRef, useState } from "react"
import ContactUsAreas from "./ContactUsAreas"
import ContactUsModelSelectorDesktop from "./ContactUsModelSelectorDesktop"
import ContactUsModelSelectorMobile from "./ContactUsModelSelectorMobile"
import ContactUsSchema, {
  initialAppointmentFormState,
  initialFormState,
} from "./ContactUsSchema"

const StyledFull = (props) => css`
  width: 100%;
  ${props.theme.breakpoints.up("md")} {
    grid-column: span 2;
  }
`

const Styled = {
  Wrapper: styled.div`
    margin: 0 0 5px;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin: 0;
    }
  `,
  Title: styled(Typography)`
    margin: 27px 0 25px;
    text-align: left; // flipped by mui
    font-size: 28px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.marine};
    width: 100%;
    letter-spacing: -0.56px;
    line-height: 1;

    ${(p) => p.theme.breakpoints.up("md")} {
      text-align: center;
      letter-spacing: -0.68px;
      font-size: 48px;
      margin: 35px auto 45px;
    }
  `,
  Flex: styled.div`
    display: grid;
    row-gap: 5px;
    column-gap: 18px;
    grid-template-columns: 1fr;
    ${(p) => p.theme.breakpoints.up("md")} {
      row-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }
  `,
  Full: styled.div`
    ${StyledFull}
  `,
  CheckboxWrapper: styled.div`
    ${StyledFull}
    ${(p) => p.theme.breakpoints.down("sm")} {
      margin-bottom: 15px;
    }
  `,
}

type ContactUsFormProps = {
  appointment?: boolean
  modelId?: number | null
}

const ContactUsForm = ({
  appointment = false,
  modelId = null,
}: ContactUsFormProps) => {
  const { models } = useContext(GlobalContext)
  const formikRef = useRef<FormikProps<typeof initialFormState>>()
  const isDesktop = useIsDesktop()
  const { query } = useRouter()
  const [status, setStatus] = useState<"success" | "failed" | "form">("form")

  const initialValues = appointment
    ? initialAppointmentFormState
    : initialFormState

  initialValues.modelId = modelId != null ? modelId.toString() : ""

  useEffect(() => {
    initialValues.platform = isDesktop ? "Pc" : "Mobile"
    formikRef.current.setFieldValue("platform", initialValues.platform)
  }, [isDesktop, initialValues])

  useEffect(() => {
    initialValues.mediaChannelId = Array.isArray(query.ref)
      ? query.ref[0]
      : (query.ref ?? null)
    formikRef.current.setFieldValue(
      "mediaChannelId",
      initialValues.mediaChannelId,
    )
  }, [query, initialValues])

  return (
    <Styled.Wrapper>
      <CommonForm
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={ContactUsSchema}
        onSubmit={async (values, actions) => {
          const utmParams = getUtmParams().utmValues
          const modelId = parseInt(values.modelId)
          let comment = values.comment
          if (modelId === 7){
            comment = comment + " E-TRANSIT"
          }
          const {
            data: { body: isSuccess },
          } = await api.postContact({
            ...values,
            modelId,
            comment,
            utmValues: utmParams,
            formName: appointment
              ? "Ford_CV_meeting_form"
              : values.subjectType === "Service"
                ? "Ford_CV_contact_service_form"
                : "Ford_CV_contact_sales_form",
            sid: window?.__ctm?.config?.sid || "",
          })
          if (isSuccess) {
            actions.resetForm()
            setStatus("success")
            trackLead()
          } else {
            setStatus("failed")
          }
        }}
        render={({ values: { subjectType } }) => (
          <Styled.Flex>
            {appointment && (
              <Styled.Full>
                <Styled.Title>קביעת פגישה עם נציג</Styled.Title>
              </Styled.Full>
            )}

            {!appointment && (
              <Styled.Full>
                <CommonToggleButtons
                  required
                  name="subjectType"
                  label="בחר נושא פניה"
                  buttons={[
                    { value: "Sale", label: "מכירות" },
                    { value: "Service", label: "שירות" },
                  ]}
                />
              </Styled.Full>
            )}

            <CommonInput
              name="firstName"
              label="שם פרטי"
              required
              autocomplete="given-name"
            />
            <CommonInput
              name="lastName"
              label="שם משפחה"
              required
              autocomplete="family-name"
            />
            <CommonInput
              name="phoneNumber"
              label="טלפון"
              required
              maxLength={10}
              autocomplete="tel-national"
            />
            <CommonInput
              name="email"
              required
              type="email"
              label='דוא"ל (Email)'
              autocomplete="email"
            />

            {subjectType === "Service" && (
              <CommonInput
                name="licenseNumber"
                placeholder="000-00-000"
                label="מספר רישוי רכב"
                required
              />
            )}

            {subjectType === "Sale" && (
              <CommonSelect
                name="modelId"
                required
                label="דגם"
                values={models}
                map={(model) => ({ id: model.id.toString(), name: model.name })}
              />
            )}

            {(subjectType === "Sale" || subjectType == "Appointment") && (
              <CommonSelect
                name="area"
                required
                label="איזור"
                values={ContactUsAreas}
                map={(area) => ({ id: area, name: area })}
              />
            )}

            {appointment && (
              <Styled.Full>
                <Desktop>
                  <ContactUsModelSelectorDesktop />
                </Desktop>
                <Mobile>
                  <ContactUsModelSelectorMobile />
                </Mobile>
              </Styled.Full>
            )}

            {(subjectType === "Sale" || subjectType == "Service") && (
              <Styled.Full>
                <CommonTextArea
                  name="comment"
                  rows={6}
                  label="תוכן הפנייה שלך"
                />
              </Styled.Full>
            )}

            <Styled.CheckboxWrapper>
              <CommonCheckbox
                size={appointment ? "small" : "medium"}
                name="isMailing"
                label={`אני רוצה לקבל פניות ועדכונים במייל ו/או בווטסאפ ו/או בסמס מקבוצת דלק מוטורס על מבצעים, השקות, אירועים ועוד, ולהיכלל במאגר המידע של הקבוצה`}
              />
            </Styled.CheckboxWrapper>

            <Styled.Full>
              <CommonSubmit
                label="פגישה עם נציג"
                align={appointment ? "center" : "right"}
              />
            </Styled.Full>
          </Styled.Flex>
        )}
      />
      <CommonResponseDialog onClose={() => setStatus("form")} status={status} />
    </Styled.Wrapper>
  )
}

export default memo(ContactUsForm)
