import styled from "@emotion/styled"
import { MenuItem } from "@mui/material"
import RatioElement from "common/ElementRatio"
import { GlobalContext } from "common/GlobalContext"
import ModelName from "common/ModelName"
import ResponsiveModelImage from "common/ResponsiveModelImage"
import { Field } from "formik"
import { TextField } from "formik-mui"
import { useContext, useRef } from "react"

const Styled = {
  Options: styled.div`
    .MuiSelect-root {
      min-height: 56px;
      display: flex;
      align-items: center;
      padding: 6px 14px 0;
      font-size: 16px;
      color: ${(p) => p.theme.colors.silver};
      .model {
        /* @noflip */
        margin-right: -14px;
        margin-left: -14px;
        width: calc(100% + 28px);
      }
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      background-color: ${(p) => p.theme.colors.paleGrey};
      padding: 6px 0 0;
    }

    .MuiPopover-root {
      position: relative !important;
      z-index: 0 !important;
      order: 3;

      .MuiPopover-paper {
        border-radius: 0;
        border: solid 1px ${(p) => p.theme.colors.darkIndigo};
        position: static;
        box-shadow: none;
        max-height: ${94 * 3}px;
        overflow-y: overlay;

        .MuiList-root,
        .MuiListItem-root {
          padding: 0;
        }
      }
    }
  `,
  Field: styled(Field)`
    width: 100%;
  `,
  Model: styled.div`
    box-sizing: border-box;
    padding: 9px 0;
    padding-left: 10px;
    height: 94px;
    display: flex;
    align-items: center;
    width: 100%;
    & > * {
      flex: 1;
    }
  `,
  ModelName: styled.div`
    margin-right: 10px;
    white-space: normal;
    /* text-align: right; */
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: #01152e;
    span {
      font-weight: normal;
    }
  `,
  EmptyItem: styled(MenuItem)`
    display: none;
  `,
}

const ContactUsModelSelectorMobile = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { models } = useContext(GlobalContext)
  return (
    <Styled.Options ref={ref}>
      <Styled.Field
        component={TextField}
        select
        name="modelId"
        required
        label="דגם"
        helperText=" "
        SelectProps={{
          displayEmpty: true,
          MenuProps: {
            container: () => ref.current,
            disableScrollLock: true,
          },
        }}
      >
        <Styled.EmptyItem value="" disabled>
          - בחר סוג דגם -
        </Styled.EmptyItem>
        {models.map((model, i) => {
          return (
            <MenuItem key={i} value={model.id.toString()}>
              <Styled.Model className="model">
                <RatioElement ratio={16 / 9}>
                  <ResponsiveModelImage
                    image={model.image}
                    imageSizes="157px"
                    transparent
                  />
                </RatioElement>
                <Styled.ModelName>
                  <ModelName name={model.name} />
                </Styled.ModelName>
              </Styled.Model>
            </MenuItem>
          )
        })}
      </Styled.Field>
    </Styled.Options>
  )
}

export default ContactUsModelSelectorMobile
