import styled from "@emotion/styled"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, InputAdornment } from "@mui/material"
import { Field } from "formik"
import { TextField } from "formik-mui"

const Styled = {
  Field: styled(Field)`
    input ~ fieldset legend {
      display: none;
    }

    width: 100%;
    ${(p) => p.theme.breakpoints.up("md")} {
      max-width: 340px;
    }
  `,
}

type CommonInputSearchProps = {
  name: string
  placeholder: string
  type?: "text" | "email"
  required?: boolean
}

const CommonInputSearch = ({
  name,
  placeholder,
  type = "text",
  required = false,
}: CommonInputSearchProps) => {
  return (
    <Styled.Field
      component={TextField}
      name={name}
      type={type}
      placeholder={placeholder}
      required={required}
      helperText=" "
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default CommonInputSearch
