import { css } from "@emotion/react"
import styled from "@emotion/styled"
import type { ReactNode } from "react"
import { useEffect, useRef, useState } from "react"

type RatioElementProps = {
  ratio: number
  children: ReactNode
  css?: boolean
  debug?: boolean
  maxWidth?: number
  className?: string
}

const DEFAULT_MOBILE_WIDTH = 360

const StyledDiv = styled.div<{ height?: number }>`
  display: block;
  width: 100%;
  position: relative;
  min-height: 1px;
  height: ${(p) => p.height + "px"};
`

const StyledCssDiv = styled.div<{
  maxWidth?: number
  ratio: number
}>`
  display: block;
  width: 100%;
  position: relative;
  aspect-ratio: ${(p) => p.ratio};
  ${(p) =>
    p.maxWidth &&
    css`
      max-width: ${p.maxWidth}px;
    `}
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

const RatioElement = ({
  ratio = 1,
  children,
  css,
  debug = false,
  maxWidth,
  className,
}: RatioElementProps) => {
  const [height, setHeight] = useState<number>(DEFAULT_MOBILE_WIDTH / ratio)
  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    if (css) return

    const listener = () => {
      if (ref.current) {
        // if (debug) debugger
        setHeight(
          Math.min(ref.current.clientWidth, maxWidth || Infinity) / ratio,
        )
      }
    }

    if (typeof window !== "undefined") {
      listener()
      window.addEventListener("resize", listener)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", listener)
      }
    }
  }, [ratio, css, debug, maxWidth])

  return css ? (
    <StyledCssDiv className={className} maxWidth={maxWidth} ratio={ratio}>
      {children}
    </StyledCssDiv>
  ) : (
    <StyledDiv className={className} height={height} ref={ref}>
      {children}
    </StyledDiv>
  )
}

export default RatioElement
