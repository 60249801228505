import styled from "@emotion/styled"
import { Close as CloseIcon } from "@mui/icons-material"
import { IconButton, IconButtonProps } from "@mui/material"

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: -56px;
  inset-inline-start: -17px;
  z-index: 1000;
  font-size: 55px;
`
const CloseButton = (props: IconButtonProps) => (
  <StyledCloseButton autoFocus aria-label="סגירה" {...props}>
    <CloseIcon color="secondary" style={{ fontSize: "34px" }} />
  </StyledCloseButton>
)

export default CloseButton
