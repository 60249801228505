import styled from '@emotion/styled'
import { GlobalContext } from "common/GlobalContext"
import { Desktop, Mobile } from 'common/MediaQueries'
import { NavigationLink } from "common/NavigationLinks"
import ContactUsDialog from "components/ContactUs/ContactUsDialog"
import { FC, useContext, useMemo, useRef } from "react"
import Footer from "./Footer/Footer"
import HeaderDesktop from "./Header/HeaderDesktop"
import HeaderMobile from "./Header/HeaderMobile"

const Styled = {
  Main: styled.main`
    flex-grow: 1;

    ${(p) => p.theme.breakpoints.up("md")} {
      display: flex;
      flex-direction: column;
    }

    :focus {
      outline: 0;
    }
  `,
}

const Layout: FC<any> = ({ children }) => {
  const { links } = useContext(GlobalContext)
  const headerSections: NavigationLink[] = useMemo(
    () => [
      links.Models,
      links.CustomerService,
      links.About,
      links.ContactUs,
      links.Ford,
    ],
    [links]
  )

  const mainRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <ContactUsDialog />
      <Desktop aboveTheFold>
        <HeaderDesktop sections={headerSections} />
      </Desktop>
      <Mobile aboveTheFold>
        <HeaderMobile sections={headerSections} />
      </Mobile>

      <Styled.Main id="mainContent" tabIndex={-1} ref={mainRef}>
        {children}
      </Styled.Main>

      <Footer />
    </>
  )
}

export default Layout
