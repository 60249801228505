export const Config = {
  WhatsappNumber: "0549528263",
  ServiceNumber: "089139995",
  ServiceEmail: "service1@delekmotors.co.il",
  MintSite: "http://www.mintapp.co.il/",
}

export default Config

export const whatsappLink = `https://wa.me/972${Config?.WhatsappNumber}`
