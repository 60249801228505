import axios, { AxiosRequestConfig } from "axios"

const API_URL = process.env.API_URL
if (!API_URL) {
  throw new Error("Please provide the API_URL environment variable.")
}

export function getUploadURL(href: string) {
  return API_URL + href
}

const config: AxiosRequestConfig = {
  baseURL: API_URL,
}

const instance = axios.create(config)

export default instance

export const CancelToken = axios.CancelToken
