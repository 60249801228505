import { ThemeOptions, createTheme } from "@mui/material"
import { heIL } from "@mui/material/locale"
import { createBreakpoints } from "@mui/system"
import colors from "./colors"

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
})

const muiThemeOptions: ThemeOptions = {
  direction: "rtl",
  spacing: 3,
  typography: {
    fontFamily: "FordAntennaCond, Heebo, sans-serif",
    // fontFamily: "var(--FordAntennaCond), var(--Heebo), sans-serif",
    h1: {
      fontSize: "28px",
      fontWeight: "bold",
      color: colors.marine,
      [breakpoints.up("md")]: {
        fontSize: "44px",
      },
    },
    h2: {
      fontSize: "1.125rem",
      fontWeight: "normal",
      letterSpacing: "0.72px",
      margin: "16px 0",
      [breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
    h3: {
      fontSize: "0.875rem",
      fontWeight: "normal",
      letterSpacing: "0.56px",
      marginTop: "24px",
      marginBottom: "20px",
      borderBottom: `1px solid ${colors.black}`,
      paddingBottom: "8px",
      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    h4: {
      fontSize: "0.875rem",
      fontWeight: "normal",
      letterSpacing: "0.84px",
      marginTop: "20px",
      marginBottom: "8px",
      color: colors.silver,
      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
  },
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.oceanBlueTwo,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.black,
    },
    text: {
      primary: colors.black,
      secondary: colors.silver,
    },
    error: {
      main: "#b50303",
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "outlined",
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(1, 21, 46, 0.55)",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingRight: "15px",
          paddingLeft: "15px",
          [breakpoints.up("sm")]: {
            paddingRight: "15px",
            paddingLeft: "15px",
          },
        },
        disableGutters: {
          paddingRight: "0",
          paddingLeft: "0",
          [breakpoints.up("sm")]: {
            paddingRight: "0",
            paddingLeft: "0",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        outlined: {
          legend: {
            fontWeight: "bold",
            fontSize: "14px",
            [breakpoints.up("md")]: {
              fontSize: "16px",
            },
          },
        },
        shrink: {
          transform: "translate(15px, -6px) scale(1)",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: "calc(100% - 30px)",
        },
        paperScrollPaper: {
          maxHeight: "calc(100% - 90px)",
          margin: "45px 15px",
          overflowY: "initial",
        },
        paper: {
          margin: "32px 6px;",
          overflow: "initial",
        },
        paperScrollBody: {
          overflow: "visible",
          margin: "50px 6px;",
          [breakpoints.down("sm")]: {
            width: "calc(100% - 12px)",
            maxWidth: "100% !important",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          boxShadow: "0 5px 99px 0 rgba(9, 42, 70, 0.6)",
          border: "solid 1px #707070",
          backgroundColor: colors.white,
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        titleWrap: { flexGrow: 0 },
        title: {
          fontSize: "1.25rem",
          [breakpoints.up("md")]: {
            fontSize: "1.5rem",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: colors.duskBlue,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableTouchRipple: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 1,
          fontWeight: "bold",
          // fontSize: "16px",
          padding: "10px 60px",
          fontSize: "20px",
          [breakpoints.down("sm")]: {
            width: "100%",
            padding: "5px 0",
            fontSize: "16px",
          },

          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        contained: {
          color: colors.white,
          backgroundColor: colors.oceanBlueTwo,
          border: `1px solid ${colors.oceanBlueTwo}`,
          outline: null,
          padding: null,
          boxShadow: null,
          "&:hover": {
            border: null,
            boxShadow: null,
            color: colors.white,
            backgroundColor: colors.oceanBlueTwo,
          },
        },
        outlined: {
          color: colors.oceanBlue,
          backgroundColor: colors.white,
          outline: null,
          padding: null,
          boxShadow: null,
          border: `1px solid ${colors.oceanBlue}`,
          "&:hover": {
            color: colors.oceanBlue,
            backgroundColor: colors.white,
            border: null,
            boxShadow: null,
          },
        },
        outlinedSecondary: {
          color: colors.white,
          backgroundColor: "transparent",
          border: `1px solid ${colors.white}`,
          "&:hover": {
            color: colors.white,
            backgroundColor: "transparent",
            border: null,
          },
        },
        sizeSmall: {
          fontSize: "14px",
          [breakpoints.down("sm")]: {
            padding: "5px 0",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          [breakpoints.down("sm")]: {
            padding: "12px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          "& legend": {
            paddingLeft: "6px",
            fontWeight: "bold",
            fontSize: "14px",
            [breakpoints.up("md")]: {
              fontSize: "16px",
            },
          },
          "& $notchedOutline": {
            borderColor: colors.silver,
            borderWidth: 1.5,
          },
          "&:hover $notchedOutline": {
            borderColor: colors.bluishGrey,
            borderWidth: 1.5,
          },
          "&$focused $notchedOutline": {
            borderColor: colors.bluishGrey,
            borderWidth: 1.5,
          },
          "&$error $notchedOutline": {
            borderColor: colors.error,
            borderWidth: 1.5,
          },
        },
        notchedOutline: {
          top: "0",
          borderColor: colors.silver,
          borderWidth: 1.5,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          flexBasis: "100%",
          color: colors.marine,
          fontWeight: "bold",
          fontSize: "14px",
          [breakpoints.up("md")]: {
            fontSize: "16px",
            transform: "translate(15px, -6px) scale(1)",
          },
          "&.Mui-error": {
            color: colors.marine,
          },
          "&.Mui-focused": {
            color: colors.marine,
          },
        },
        asterisk: {
          marginLeft: "2px",
          verticalAlign: "top",
          color: colors.error,
          [breakpoints.up("md")]: {
            fontSize: "18px",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          ".MuiFormGroup-root &": {
            display: "inline-block",
            marginRight: "0",
            marginLeft: "0",
            "& .MuiButtonBase-root": {
              display: "none",
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          borderRadius: 0,
          height: "40px",
          boxSizing: "border-box",
        },
        root: {
          "&$disabled": {
            color: colors.silver,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          "&$error": {
            fontSize: "12px",
            fontWeight: "bold",
            color: colors.error,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "3px 30px 25px 25px",
          [breakpoints.up("md")]: {
            padding: "3px 50px 50px 40px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px",
          "&$expanded": {
            minHeight: "",
          },
        },
        content: {
          "&$expanded": {
            margin: "",
          },
        },
        expandIconWrapper: {
          "&$expanded": {
            transform: "scaleY(-1)",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicatorOpen: {
          transform: "none",
        },
        inputRoot: {
          borderRadius: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: colors.bluishGrey,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.bluishGrey,
        },
        textColorInherit: {
          color: colors.bluishGrey,
        },
      },
    },
  },
}

const customTheme = createTheme({ ...muiThemeOptions, breakpoints }, heIL)

const theme = { ...customTheme, colors }
export default theme

export type Theme = typeof theme
