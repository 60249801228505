import { memo, useContext } from "react"
import Shared from "theme/shared"
import ContactUsContext from "./ContactUsContext"

type ContactUsButtonProps = {
  component?: JSX.Element
  label?: string
  size?: "small" | "large" | "medium"
  modelId?: number
}

const ContactUsButton = ({
  component = <Shared.Button variant="contained" />,
  label = "פגישה עם נציג",
  modelId = null,
  size,
}: ContactUsButtonProps) => {
  const { onOpen } = useContext(ContactUsContext)

  return (
    <component.type
      {...component.props}
      size={size}
      onClick={() => onOpen(modelId)}
    >
      {label}
    </component.type>
  )
}

export default memo(ContactUsButton)
