import styled from "@emotion/styled"
import { Dialog } from "@mui/material"
import CloseButton from "common/CloseButton"
import { FunctionComponent, useCallback, useContext, useState } from "react"
import ContactUsContext from "./ContactUsContext"
import ContactUsForm from "./ContactUsForm"

const Styled = {
  Dialog: styled(Dialog)`
    margin: 0 auto;
    max-width: 847px;
    .MuiDialog-paper {
      max-height: calc(100% - 90px);
      margin: 45px 15px;
      overflow-y: initial;
    }
  `,
  Wrapper: styled.div`
    overflow-y: auto;
    padding: 0 19px;
    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 3px 74px 9px;
    }
  `,
}

export const ContactUsDialogProvider: FunctionComponent<any> = ({
  children,
}) => {
  const [opened, setOpened] = useState(false)
  const [selectedModelId, setSelectedModelId] = useState<number>(null)
  const onClose = useCallback(() => {
    setOpened(false)
    setSelectedModelId(null)
  }, [])
  const onOpen = useCallback((modelId: number) => {
    setOpened(true)
    setSelectedModelId(modelId)
  }, [])
  return (
    <ContactUsContext.Provider
      value={{ onClose, onOpen, selectedModelId, opened }}
    >
      {children}
    </ContactUsContext.Provider>
  )
}

const ContactUsDialog = () => {
  const { opened, onClose, selectedModelId } = useContext(ContactUsContext)

  return (
    <Styled.Dialog open={opened} onClose={onClose} maxWidth="md">
      <CloseButton onClick={onClose} />
      <Styled.Wrapper>
        <ContactUsForm appointment modelId={selectedModelId} />
      </Styled.Wrapper>
    </Styled.Dialog>
  )
}

export default ContactUsDialog
