import * as api from "api"
import { GlobalContextType } from "common/GlobalContext"
import NavigationLinks from "common/NavigationLinks"

export type IntialProps = {
  props: GlobalContextType
}

export async function getInitialProps(): Promise<IntialProps> {
  const models = await api.getVehicleModels().then(api.extractData)
  const contacts = await api.getContactSalesContacts().then(api.extractData)
  const generalSetting = await api.getGeneralSettings().then(api.extractData)

  const settings = generalSetting.reduce(
    (t, { key, value }) => ({ ...t, [key]: value }),
    <GlobalContextType["settings"]>{}
  )

  const links = NavigationLinks(models)

  return {
    props: {
      models,
      contacts,
      settings,
      links,
    },
  }
}
