import * as api from "./generated/FordInterfaces"

export type VehicleTypes = api.VehicleDtoResponseWrapper["body"]

export type ChildTypes = api.VehicleChildren | api.VehicleFinishingLevel

export class Types {
  static isFinishingLevel(
    child: ChildTypes,
    familyType: api.FamilyTypes
  ): child is api.VehicleFinishingLevel {
    return familyType === "FSeries"
  }

  static getSectionProps(familyType: api.FamilyTypes) {
    return familyType === "FSeries"
      ? {
          id: "finishing-levels",
          title: "רמות גימור",
        }
      : {
          id: "child-models",
          title: "דגמי בן",
        }
  }
}
