import { css } from "@emotion/react"

const AntennaCondFontFaces = css`
  @font-face {
    font-family: "FordAntennaCond";
    src: url("/fonts/Antenna-Cond-Light.eot");
    src:
      url("/fonts/Antenna-Cond-Light.eot?") format("embedded-opentype"),
      url("/fonts/Antenna-Cond-Light.woff") format("woff"),
      url("/fonts/Antenna-Cond-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    unicode-range: U+0000-017F;
  }
  @font-face {
    font-family: "FordAntennaCond";
    src: url("/fonts/Antenna-Cond-Regular.eot");
    src:
      url("/fonts/Antenna-Cond-Regular.eot?") format("embedded-opentype"),
      url("/fonts/Antenna-Cond-Regular.woff") format("woff"),
      url("/fonts/Antenna-Cond-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
    unicode-range: U+0000-017F;
  }
  @font-face {
    font-family: "FordAntennaCond";
    src: url("/fonts/Antenna-Cond-Medium.eot");
    src:
      url("/fonts/Antenna-Cond-Medium.eot?") format("embedded-opentype"),
      url("/fonts/Antenna-Cond-Medium.woff") format("woff"),
      url("/fonts/Antenna-Cond-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    unicode-range: U+0000-017F;
  }
  @font-face {
    font-family: "FordAntennaCond";
    src: url("/fonts/Antenna-Cond-Bold.eot");
    src:
      url("/fonts/Antenna-Cond-Bold.eot?") format("embedded-opentype"),
      url("/fonts/Antenna-Cond-Bold.woff") format("woff"),
      url("/fonts/Antenna-Cond-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
    unicode-range: U+0000-017F;
  }
`

const HeeboFontFaces = css`
  @font-face {
    font-family: "Heebo";
    src: url("/fonts/Heebo-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: "Heebo";
    src: url("/fonts/Heebo-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Heebo";
    src: url("/fonts/Heebo-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Heebo";
    src: url("/fonts/Heebo-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
  }
`

const GlobalStyle = css`
  ${AntennaCondFontFaces}
  ${HeeboFontFaces}
  :root {
    direction: rtl;
    /* font-family: "var(--FordAntennaCond), var(--Heebo), sans-serif; */
    font-family: FordAntennaCond, Heebo, sans-serif;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export default GlobalStyle
