import styled from "@emotion/styled"
import { Field } from "formik"
import { TextField } from "formik-mui"

const Styled = {
  Field: styled(Field)`
    width: 100%;
  `,
}

type CommonInputProps = {
  name: string
  label: string
  type?: "text" | "email" | "tel"
  required?: boolean
  maxLength?: number
  placeholder?: string
  autocomplete?: "given-name" | "family-name" | "email" | "tel-national" | "off"
}

const CommonInput = ({
  name,
  label,
  type = "text",
  required = false,
  placeholder,
  maxLength,
  autocomplete,
}: CommonInputProps) => {
  return (
    <Styled.Field
      component={TextField}
      id={name}
      name={name}
      autoComplete={autocomplete}
      type={type}
      label={label}
      required={required}
      helperText=" "
      placeholder={placeholder}
      inputProps={{ maxLength }}
    />
  )
}

export default CommonInput
