import styled from "@emotion/styled"
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined"
import * as api from "api"
import Image from "next/image"
import { memo, useState } from "react"

const maxImageSize = 1200

// function getRandomColor() {
//   const letters = "0123456789ABCDEF"
//   let color = ""
//   let back = ""
//   for (let i = 0; i < 3; i++) {
//     const r = Math.floor(Math.random() * 8)
//     color += letters[r]
//     back += letters[16 - r]
//   }
//   return `${color}/${back}`
// }

const Styled = {
  ModelImageContainer: styled.div`
    width: 100%;
    height: 100%;
    max-width: ${maxImageSize}px;
    overflow: hidden;
    position: relative;

    /* ${(p) => p.theme.breakpoints.down("sm")} {
      width: auto !important;
    } */
  `,
  Error: styled.div`
    width: 100%;
    height: 100%;
    border: 1px dotted ${({ theme }) => theme.colors.cloudyBlue};
    background-color: ${({ theme }) => theme.colors.darkIndigo};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: 75px;
      width: auto;
      height: 90%;
      opacity: 0.05;
    }
  `,
}

const getSizes = (size: string[] | string) => {
  let sizes: string
  switch (typeof size) {
    case "string":
      sizes = size
      break
    default:
      sizes =
        size.length > 1 ? `(min-width: 768px) ${size[1]}, ${size[0]}` : size[0]
  }
  return sizes
}

type ResponsiveModelImageProps = {
  image: api.ImageModel
  /**
   * Represents the size of the image in the screen width in vw or in px
   * if Array, first value for mobile and second value desktop
   *
   * @default "100vw"
   */
  imageSizes?: string[] | string
  transparent?: boolean
  cover?: boolean
  priority?: boolean
} & React.HTMLProps<HTMLImageElement>

const ResponsiveModelImage = ({
  image,
  imageSizes = "100vw",
  cover = false,
  className,
  priority = false,
}: ResponsiveModelImageProps) => {
  const [error, setError] = useState(false)

  const onError = () => setError(true)

  const objectFit = cover ? "cover" : "contain"

  return (
    <Styled.ModelImageContainer>
      {error && (
        <Styled.Error>
          <BrokenImageOutlinedIcon color="primary" />
        </Styled.Error>
      )}
      {!error && (
        <Image
          src={image?.imageUrl}
          sizes={getSizes(imageSizes)}
          alt={`${image?.alt}`}
          onError={onError}
          className={className}
          fill
          priority={priority}
          style={{ objectFit }}
        />
      )}
    </Styled.ModelImageContainer>
  )
}

export default memo(ResponsiveModelImage)
