import styled from '@emotion/styled'
import { SvgIcon, SvgIconProps } from '@mui/material'

const StyledPath = styled.path`
  stroke: ${(p) => p.theme.colors.duskBlue};
  fill: ${(p) => p.theme.colors.duskBlue};
`
const FailedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 70.301 70.301">
    <g transform="translate(-4.5 -4.5)">
      <StyledPath d="M39.65 5A34.65 34.65 0 1 0 74.3 39.65 34.7 34.7 0 0 0 39.65 5zm0 66.067A31.378 31.378 0 1 1 71.067 39.65 31.449 31.449 0 0 1 39.65 71.067z" />
      <StyledPath
        d="M49 46.687l8.316-8.316c1.463-1.463-.847-3.773-2.31-2.31l-8.316 8.316-8.319-8.317c-1.463-1.463-3.773.847-2.31 2.31l8.316 8.316L36.06 55c-1.463 1.463.847 3.773 2.31 2.31L46.687 49 55 57.313c1.463 1.463 3.773-.847 2.31-2.31z"
        transform="translate(-7.036 -7.036)"
      />
    </g>
  </SvgIcon>
)

export default FailedIcon
