import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { ReactNode, Ref } from "react"

type CommonFormProps<Schema, Values> = {
  validationSchema: Schema
  initialValues: Values
  render(props: FormikProps<Values>): ReactNode
  onSubmit(values: Values, actions: FormikHelpers<Values>): Promise<void>
  innerRef?: Ref<FormikProps<Values>>
}

function CommonForm<Schema, Values>({
  validationSchema,
  initialValues,
  render,
  onSubmit,
  innerRef,
}: CommonFormProps<Schema, Values>) {
  return (
    <Formik
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)
        await onSubmit(values, actions)
        actions.setSubmitting(false)
      }}
    >
      {(formik) => <Form noValidate>{render(formik)}</Form>}
    </Formik>
  )
}

export default CommonForm
