import googleTagManager from "@analytics/google-tag-manager"
import Analytics from "analytics"

const GTM_ID = process.env.GTM_ID
if (!GTM_ID) {
  throw new Error("Please provide the GTM_ID environment variable.")
}

/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: "Ford Commercials",
  plugins: [googleTagManager({ containerId: GTM_ID })],
})

export const trackPage = () => analytics.page()


export const trackLead = () => analytics.track("meeting_lead")
