import { NavigationLink } from "common/NavigationLinks"
import NextLink from "common/NextLink"
import ContactUsButton from "components/ContactUs/ContactUsButton"
import nextLinks from "next.links"
import { useRouter } from "next/router"
import { memo, useEffect, useState } from "react"
import HeaderNavBar from "./HeaderNavBar"
import Styled, { DESKTOP_EXPANDED_HEIGHT, DESKTOP_HEIGHT } from "./HeaderStyled"

type HeaderDesktopProps = {
  sections: NavigationLink[]
}

const HeaderDesktop = ({ sections }: HeaderDesktopProps) => {
  const { pathname } = useRouter()
  const [expanded, setExpanded] = useState<NavigationLink>(null)
  const [active, setActive] = useState<NavigationLink>(null)

  useEffect(() => {
    let _active: NavigationLink = null
    let _expanded: NavigationLink = null
    const route =
      nextLinks[pathname.replace(/^\//, "").replace(/\/.+$/, "")] || pathname
    for (const section of sections) {
      if (typeof section.link === "string") {
        if (section.link === route || section.subLink == route) {
          _active = section
          _expanded = section
          break
        }
      } else {
        const inner = section.link.find(
          (item) => item.link === route || item.subLink == route
        )
        if (inner) {
          _active = inner
          _expanded = section
          break
        }
      }
    }

    setActive(_active)
    setExpanded(_expanded)
  }, [pathname, sections])

  const subLinks =
    Array.isArray(expanded?.link) && expanded.link.length > 0
      ? expanded.link
      : null

  useEffect(() => {
    const height = subLinks ? DESKTOP_EXPANDED_HEIGHT : DESKTOP_HEIGHT
    document.documentElement.style.setProperty("--sticky-height", `${height}px`)
  }, [subLinks])

  return (
    <Styled.AppBar position="sticky" elevation={0}>
      <Styled.Toolbar>
        <Styled.Container>
          <Styled.HeaderSide justify="start" flex={4}>
            <NextLink href="/">
              <Styled.Logo
                src="/LOGO.svg"
                alt="פורד מסחריות"
                width="83"
                height="31"
              />
            </NextLink>
            {sections.map((section, index) =>
              typeof section.link === "string" ? (
                <NextLink key={index} href={section.link} passHref>
                  <Styled.Button selected={section == expanded}>
                    {section.title}
                  </Styled.Button>
                </NextLink>
              ) : (
                <Styled.Button
                  key={index}
                  selected={section == expanded}
                  onClick={() =>
                    setExpanded(section === expanded ? null : section)
                  }
                >
                  {section.title}
                </Styled.Button>
              )
            )}
          </Styled.HeaderSide>
          <Styled.HeaderSide justify="end" flex={1}>
            <ContactUsButton
              size="small"
              component={<Styled.MeetingButton variant="contained" />}
            />
          </Styled.HeaderSide>
        </Styled.Container>
      </Styled.Toolbar>

      <HeaderNavBar subLinks={subLinks} active={active} />
    </Styled.AppBar>
  )
}

export default memo(HeaderDesktop)
