import styled from "@emotion/styled"
import { FormControlLabel, FormLabel, Radio } from "@mui/material"
import RatioElement from "common/ElementRatio"
import { GlobalContext } from "common/GlobalContext"
import ModelName from "common/ModelName"
import ResponsiveModelImage from "common/ResponsiveModelImage"
import { Field } from "formik"
import { RadioGroup } from "formik-mui"
import { useContext } from "react"

const Styled = {
  Selector: styled(Field)`
    width: 100%;
    flex-direction: column;
    margin-bottom: 25px;

    img {
      height: 72px;
    }

    .MuiFormControlLabel-label {
      display: block;
      opacity: 0.6;
    }

    .Mui-checked + .MuiFormControlLabel-label {
      opacity: 1;
    }
  `,
  FormLabel: styled(FormLabel)`
    transform: none !important;
    margin-bottom: 5px;
  `,

  Models: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 1fr;

    margin-top: 0;
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
  `,
  Model: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ModelName: styled.div`
    flex-grow: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.bluishGrey};
    text-transform: uppercase;
    margin-top: 10px;
    span {
      font-weight: normal;
    }
  `,
}

const ContactUsModelSelectorDesktop = () => {
  const { models } = useContext(GlobalContext)
  return (
    <Styled.Selector component={RadioGroup} name="modelId">
      <Styled.FormLabel required>בחירת סוג דגם</Styled.FormLabel>
      <Styled.Models>
        {models.map((model, i) => (
          <FormControlLabel
            name="modelId"
            key={i}
            value={model.id.toString()}
            control={<Radio />}
            label={
              <Styled.Model>
                <RatioElement ratio={16 / 9}>
                  <ResponsiveModelImage
                    image={model.image}
                    imageSizes="158px"
                  />
                </RatioElement>
                <Styled.ModelName>
                  <ModelName name={model.name} />
                </Styled.ModelName>
              </Styled.Model>
            }
          />
        ))}
      </Styled.Models>
    </Styled.Selector>
  )
}

export default ContactUsModelSelectorDesktop
