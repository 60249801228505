import styled from "@emotion/styled"
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { NavigationLink } from "common/NavigationLinks"
import NextLink from "common/NextLink"

const StyledDivBorder = styled.div`
  border-top: 2px solid ${(p) => p.theme.colors.paleGrey};
`
const StyledListItem = styled(ListItemButton)`
  background-color: ${(p) => p.theme.colors.paleGrey};
  border-radius: 0;
`

type HeaderMenuItemProps = {
  navLink: NavigationLink
  selected: NavigationLink | null
  onSelect: (item: NavigationLink) => void
  onClose: () => void
}

const HeaderMenuItem = ({
  navLink,
  onClose,
  onSelect,
  selected,
}: HeaderMenuItemProps) => {
  const { title, link } = navLink
  const handleDrawerClose = onClose
  return (
    <div>
      {typeof link == "string" && (
        <StyledDivBorder>
          <NextLink href={link} passHref>
            <ListItemButton
              component={Button}
              autoFocus
              onClick={handleDrawerClose}
              style={{ paddingRight: 15 }}
            >
              <ListItemText primary={title} />
            </ListItemButton>
          </NextLink>
        </StyledDivBorder>
      )}
      {Array.isArray(link) && (
        <>
          <StyledDivBorder>
            <ListItemButton
              onClick={() => onSelect(selected === navLink ? null : navLink)}
            >
              <ListItemText primary={title} />
            </ListItemButton>
          </StyledDivBorder>

          <Collapse in={selected === navLink} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {link.map((item, index) => (
                <NextLink key={index} href={item.link} passHref>
                  <StyledListItem autoFocus onClick={handleDrawerClose}>
                    <ListItemText primary={item.title} />
                  </StyledListItem>
                </NextLink>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </div>
  )
}

export default HeaderMenuItem
