import styled from "@emotion/styled"
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp"
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp"
import { Field } from "formik"
import { CheckboxWithLabel } from "formik-mui"

const Styled = {
  Checkbox: styled.div`
    width: 100%;
  `,
  Field: styled(Field)`
    align-items: flex-start;
    margin-top: -13px;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: -11px;
    }

    & + .MuiFormControlLabel-label {
      color: rgba(1, 21, 46, 0.8);
      font-size: 12px;
      font-weight: bold;
      text-align: left; /* flipped by mui */
      ${(p) => p.theme.breakpoints.up("md")} {
        font-size: ${(p) => (p.size == "medium" ? "16px" : "14px")};
        font-weight: ${(p) => (p.size == "medium" ? "normal" : "bold")};
      }
    }

    svg {
      width: auto;
      height: 25px;
      ${(p) => p.theme.breakpoints.up("md")} {
        height: 30px;
      }
    }
  `,
}

type CommonCheckboxProps = {
  name: string
  label: string
  required?: boolean
  size?: "small" | "medium"
}

const CommonCheckbox = ({
  name,
  label,
  required = false,
  size = "medium",
}: CommonCheckboxProps) => (
  <Styled.Checkbox>
    <Styled.Field
      component={CheckboxWithLabel}
      type="checkbox"
      name={name}
      size={size}
      required={required}
      icon={<CheckBoxOutlineBlankSharpIcon color="inherit" />}
      checkedIcon={<CheckBoxSharpIcon color="primary" />}
      Label={{
        label,
      }}
    />
  </Styled.Checkbox>
)

export default CommonCheckbox
