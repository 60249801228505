import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { getInitialProps, IntialProps } from "api/Initial"
import { trackPage } from "common/analytics"
import { GlobalContext } from "common/GlobalContext"
import MarkdownProvider from "common/MarkdownProvider"
import { ContactUsDialogProvider } from "components/ContactUs/ContactUsDialog"
import Layout from "layout/Layout"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
import { Router, useRouter } from "next/router"
import { useEffect } from "react"
import theme from "theme/theme"
import ThemeProviders from "theme/ThemeProviders"

const ORIGIN = process.env.ORIGIN
if (!ORIGIN) {
  throw new Error("Please provide the ORIGIN environment variable.")
}

const AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY

Router.events.on("routeChangeComplete", () => {
  trackPage()
  window.appInsights?.trackPageView()
})

const MyApp = (appProps: AppProps & IntialProps) => {
  const { Component, pageProps, props } = appProps
  useEffect(() => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector("#jss-server-side")
    jssStyles?.parentElement?.removeChild(jssStyles)

    // Initialize Azure Application Insights
    if (AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY && !window.appInsights) {
      window.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
        },
      })
      window.appInsights.loadAppInsights()
      window.appInsights.trackPageView()
    }
  }, [])

  const { asPath } = useRouter()
  const url = (ORIGIN + asPath).split(/[?#]/)[0] // remove query string & hash
  return (
    <>
      <DefaultSeo
        titleTemplate="%s - פורד מסחריות"
        canonical={url}
        openGraph={{
          site_name: "פורד מסחריות",
          locale: "he_IL",
          type: "website",
          url,
        }}
        additionalMetaTags={[
          { name: "theme-color", content: theme.colors.silver },
        ]}
      />
      <ThemeProviders appProps={appProps}>
        <GlobalContext.Provider value={props}>
          <MarkdownProvider>
            <ContactUsDialogProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ContactUsDialogProvider>
          </MarkdownProvider>
        </GlobalContext.Provider>
      </ThemeProviders>
    </>
  )
}

MyApp.getInitialProps = getInitialProps

export default MyApp
