import { createContext } from "react"

export type ContactUsContextType = {
  onClose: () => void
  onOpen: (modelId: number | null) => void
  opened: boolean
  selectedModelId: number | null
}

export const ContactUsContext = createContext<ContactUsContextType>(null)

export default ContactUsContext
