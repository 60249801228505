const areas: string[] = [
  "צפון",
  "שרון",
  "שפלה",
  "מרכז (ללא תל אביב)",
  "תל אביב",
  "ירושלים והסביבה",
  "דרום",
]
export default areas
