import Link from "next/link"
import { useRouter } from "next/router"
import React, { memo } from "react"
import type { UrlObject } from "url"

type NextLinkProps = {
  href: string
  passHref?: boolean
  openInNewTab?: boolean
  children: React.ReactNode
  notEncoding?: boolean
  className?: string
}

const NextLink: React.FunctionComponent<NextLinkProps> = ({
  href,
  passHref,
  children,
  openInNewTab = false,
  notEncoding = false,
  className,
}) => {
  const { asPath } = useRouter()
  const idx = asPath.indexOf("?")
  const search = idx >= 0 ? asPath.slice(idx) : undefined
  const urlWithHash = href.split("#")
  const link: UrlObject = {
    pathname: urlWithHash[0],
    search,
    hash: urlWithHash[1] ?? null,
  }

  if (openInNewTab) {
    return (
      <Link
        className={className}
        href={notEncoding ? href : link}
        {...{ target: "_blank", rel: "noreferrer" }}
      >
        {children}
      </Link>
    )
  }

  return (
    <Link
      className={className}
      href={notEncoding ? href : link}
      passHref={passHref}
      legacyBehavior={passHref}
    >
      {children}
    </Link>
  )
}

export default memo(NextLink)
