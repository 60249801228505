import styled from '@emotion/styled'
import { Dialog, Typography } from '@mui/material'
import CloseButton from "common/CloseButton"
import { GlobalContext } from "common/GlobalContext"
import NextLink from "common/NextLink"
import FailedIcon from "common/icons/Form/FailedIcon"
import SuccessIcon from "common/icons/Form/SuccessIcon"
import { memo, useContext } from "react"
import Shared from "theme/shared"

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    padding: 54px 24px 63px;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: auto;
      height: 70px;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 70px 82px 68px;
      svg {
        height: 105px;
      }
    }
  `,
  Title: styled(Typography)`
    text-align: center;
    color: ${(p) => p.theme.colors.duskBlue};
    margin-top: 16px;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 22px;
    }
  `,
  Subtitle: styled(Typography)`
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: ${(p) => p.theme.colors.marine};
    margin: 12px 0 24px;
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 20px;
      margin: 18px 0 30px;
    }
  `,
}

type CommonResponseDialogProps = {
  onClose: () => void
  status: "failed" | "success" | string
}

const CommonResponseDialog = ({
  onClose,
  status,
}: CommonResponseDialogProps) => {
  const { links } = useContext(GlobalContext)
  return (
    <Dialog open={status == "failed" || status == "success"} onClose={onClose}>
      <CloseButton onClick={onClose} />
      <Styled.Wrapper>
        {status == "success" && (
          <>
            <SuccessIcon />
            <Styled.Title variant='h1'>הבקשה לפגישה נשלחה</Styled.Title>
            <Styled.Subtitle>נציג מטעמנו ייצור איתך קשר בהקדם</Styled.Subtitle>
          </>
        )}
        {status == "failed" && (
          <>
            <FailedIcon />
            <Styled.Title  variant='h1'>משהו השתבש</Styled.Title>
            <Styled.Subtitle>
              אנחנו בודקים את הנושא, בינתיים ניתן לנסות שוב או להתקשר לאחד{" "}
              <NextLink href={links.ContactUs.link}>
                <u onClick={onClose}>מהנציגים שלנו</u>
              </NextLink>
            </Styled.Subtitle>
          </>
        )}
        <Shared.Button variant="contained" onClick={onClose}>
          חזרה לאתר
        </Shared.Button>
      </Styled.Wrapper>
    </Dialog>
  )
}

export default memo(CommonResponseDialog)
