import styled from '@emotion/styled'
import { Divider, List, Drawer as MuiDrawer } from '@mui/material'
import { NavigationLink } from "common/NavigationLinks"
import ContactUsButton from "components/ContactUs/ContactUsButton"
import { useState } from "react"
import HeaderMenuItem from "./HeaderMenuItem"

const StyledDivider = styled(Divider)`
  height: 2px;
  background-color: ${(p) => p.theme.colors.paleGrey};
`
const StyledDrawer = styled(({ ...props }) => (
  <MuiDrawer classes={{ paper: "paper" }} {...props} />
))`
  & .paper {
    width: 240px;

    ${(p) => p.theme.breakpoints.up("md")} {
      width: 280px;
    }
  }
`
const StyledButtonRow = styled.div`
  margin: 20px auto 10px;
  width: 132px;
  margin-top: 20px;
  margin-bottom: 10px;
`
type HeaderSideDrawerProps = {
  sections: NavigationLink[]
  onClose: () => void
  open: boolean
}

const HeaderSideDrawer = ({
  sections,
  onClose,
  open,
}: HeaderSideDrawerProps) => {
  const [selected, setSelected] = useState<NavigationLink>(null)
  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledButtonRow>
        <ContactUsButton size="small" />
      </StyledButtonRow>

      <List component="div">
        {sections.map((item, index) => (
          <HeaderMenuItem
            key={index}
            selected={selected}
            onSelect={setSelected}
            navLink={item}
            onClose={onClose}
          />
        ))}
        <StyledDivider />
      </List>
    </StyledDrawer>
  )
}

export default HeaderSideDrawer
